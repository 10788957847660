@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import '@adyen/adyen-web/dist/adyen.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-quartz.css';
@import 'ag-grid-community/styles/ag-theme-material.css';
@import '@fortawesome/fontawesome-svg-core/styles.css';

/* STYLES FOR AG-GRID */
.ag-theme-material {
  --ag-header-background-color: theme('colors.rows-header-background');
  --ag-menu-background-color: theme('colors.rows-header-background');
  --ag-panel-background-color: theme('colors.rows-header-background');
  --ag-row-hover-color: theme('colors.rows-highlight-color');
  --ag-header-cell-hover-background-color: theme('colors.rows-highlight-color');
  --ag-header-cell-moving-background-color: theme('colors.rows-highlight-color');
  --ag-borders-critical: none;
  --ag-borders-secondary: none;
  --ag-font-family: theme('fontFamily.sans');
  --ag-font-size: 20px;
  --ag-header-foreground-color: theme('colors.black');
}

/* STYLES FOR SNACKBAR */

.cdk-overlay-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.mdc-snackbar__surface {
  border: none !important;
  box-shadow: none !important;
}

.mat-mdc-snack-bar-label {
  padding: 0 !important;
}

/* END STYLES FOR SNACKBAR */


/* STYLES FOR MAT TAB */

.mat-mdc-tab-header {
  background-color: rgb(240 249 255);
  font-weight: bold;
}

.mdc-tab-indicator {
  display: none !important;
}

.mdc-tab-indicator__content mdc-tab-indicator__content--underline {
  display: none !important;
}

.mdc-tab-indicator--active {
  border-bottom: 2px solid black !important;
}

/* END STYLES FOR MAT TAB */

/* ALWAYS KEEP DIALOGS IN FRONT OF MAT TAB */

.mat-mdc-tab-body, .mat-mdc-tab-label-container {
  z-index: 0 !important;
}

.mat-mdc-tab-body.mat-mdc-tab-body-active {
  z-index: 1 !important;
}

.cdk-overlay-container {
  z-index: 2;
}

/* END ALWAYS KEEP DIALOGS IN FRONT OF MAT TAB */

/* STYLES FOR LOCATION MANAGER */

#sectionCanvas {
  user-scalable: no;
  touch-action: none;
}

/* END STYLES FOR LOCATION MANAGER */

/* STYLES FOR ADYEN DROP IN */

.adyen-checkout__button {
  background: #2265ab;
  box-shadow: 0 0, 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14);
}

/* END STYLES FOR ADYEN DROP IN */
